import { lazy } from 'react';
import { AppRoles } from '../helpers/constants';
const CreateClientAccount = lazy(() =>
	import('../pages/ClientAccountDetails/ClientAccountCreator')
);
const CourseDetail = lazy(() => import('../pages/Courses/CourseDetails'));
const CreateUser = lazy(() => import('../pages/UserData/CreateUsers'));
const UserDetail = lazy(() => import('../pages/UserData/UserDetails'));

const CreateLessons = lazy(() => import('../pages/OldLesson/createLesson'));
const LessonDetails = lazy(() => import('../pages/OldLesson/lessonDetails'));
const Lessons = lazy(() => import('../pages/Lessons/Lessons'));
const ViewLesson = lazy(() => import('../pages/Lessons/ViewLessonDetails'));
const LessonPreview = lazy(() => import('../pages/OldLesson/lessonPreview'));

const RubricDetailedContent = lazy(() => import('../pages/Rubric/RubricDetailContent'));
const createRubrics = lazy(() => import('../pages/Rubric/RubricCreator'));

const createCourse = lazy(() => import('../pages/Courses/CreateBasicCourse'));

const Curriculum = lazy(() => import('../pages/ContentLibrary/ContentLibrary'));

const PublishSettingModal = lazy(() => import('../components/PublishSettingModal'));

const CourseContentPreview = lazy(() => import('../pages/Courses/CourseContentsPreview'));

const Users = lazy(() => import('../pages/UserData/UserListing'));

// student manage UI
const MyProfile = lazy(() => import('../pages/UserData/ProfileDetails'));
const ChangePassword = lazy(() => import('../pages/PasswordChange/PasswordChange'));

// Course Schedule
const CourseScheduleList = lazy(() => import('../pages/CourseSchedules/CourseScheduleListing'));
const CreateCourseSchedule = lazy(() => import('../pages/CourseSchedules/CreateCourseSchedules'));

const Groups = lazy(() => import('../pages/StudentGroups/StudentGroups'));
const CreateGroup = lazy(() => import('../pages/StudentGroups/GroupCreator'));

// Course Training Insights
const TrainingInsights = lazy(() => import('../pages/CourseTrainingInsight/TrainingInsight'));
const TrainingInsightsDetails = lazy(() =>
	import('../pages/CourseTrainingInsight/TrainingInsightDetails')
);

//Course Manager Dashboard
const CourseManagerDashboard = lazy(() =>
	import('../pages/CourseManagerDashboard/CourseManagerDashboardView')
);

//Instructor Dashboard
const InstructorDashboard = lazy(() =>
	import('../pages/InstructorsDashboard/InstructorsDashboard')
);

//student Dashboard
const StudentDashboard = lazy(() => import('../pages/Student/StudentDashboardView'));
const StudentCourseDetail = lazy(() => import('../pages/Student/StudentcourseDetail'));

// Manage Client Accounts
const ClientAccountList = lazy(() => import('../pages/ClientAccountDetails/ClientAccountListing'));
const ClientDetail = lazy(() => import('../pages/ClientAccountDetails/ClientDetails'));

const PracticalAssessment = lazy(() => import('../pages/PracticalAssesments/PracticalAssesments'));
const PracticalAssessmentDetail = lazy(() =>
	import('../pages/PracticalAssesments/PracticalAssessmentDetails')
);

const StudentAssessmentDetails = lazy(() => import('../pages/Student/StudentAssessmentDetails'));
const TopicList = lazy(() => import('../pages/Student/TopicMastery/TopicList'));
const TopicMasteryQuestionSet = lazy(() => import('../pages/Student/TopicMastery/QuestionSet'));
const ResultList = lazy(() => import('../pages/Student/TopicMastery/ResultList'));
const MemoryCheckResult = lazy(() => import('../pages/Student/TopicMastery/MemoryCheckResult'));

const InstructionDetails = lazy(() =>
	import('../pages/Student/StudentAssessmentDetails/InstructionDetails')
);
const StudentAssessmentTaskView = lazy(() =>
	import('../pages/Student/StudentAssessmentDetails/StudentAssessmentTaskView')
);

const RubricAssessmentDetails = lazy(() =>
	import('../pages/Student/StudentAssessmentDetails/RubricAssessmentDetails')
);
const SummaryAssessment = lazy(() =>
	import('../pages/Student/StudentAssessmentDetails/SummaryAssessment')
);

//Reports
const ReportsL0 = lazy(() => import('../pages/Report/ReportsL0'));
const ReportsL1 = lazy(() => import('../pages/Report/ReportsL1'));
const ReportsL2 = lazy(() => import('../pages/Report/ReportsL2'));
const ReportsL3 = lazy(() => import('../pages/Report/ReportsL3'));
const ContentDetails = lazy(() => import('../pages/Report/ContentPreview'));

//FAQ
const ViewFAQ = lazy(() => import('../pages/FAQ/ViewFAQ'));
const ListFAQ = lazy(() => import('../pages/FAQ/ListFAQ'));
const CreateFAQ = lazy(() => import('../pages/FAQ/CreateFAQ'));

//SettingTool
const BillingInfo = lazy(() => import('../pages/SettingsTool/BillingInfo'));

//NoAccessPage
const NoAccessPage = lazy(() => import('../pages/NoAccessPage'));

const routes = [
	{
		exact: true,
		name: 'RubricDetailedContent',
		path: '/view-scoring-matrix',
		component: RubricDetailedContent,
		visibleTo: [AppRoles.CONTENT_ADMIN, AppRoles.TRAINER],
	},

	{
		exact: true,
		name: 'CreateRubrics',
		path: '/create-scoring-matrix',
		component: createRubrics,
		visibleTo: [AppRoles.CONTENT_ADMIN, AppRoles.TRAINER],
	},
	{
		exact: true,
		name: 'CreateCourseBasic',
		path: '/create-course',
		component: createCourse,
		visibleTo: [AppRoles.CONTENT_ADMIN, AppRoles.TRAINER],
	},
	{
		exact: true,
		name: 'Curriculum',
		path: '/content-library',
		component: Curriculum,
		visibleTo: [AppRoles.CONTENT_ADMIN, AppRoles.TRAINER],
	},
	{
		exact: true,
		name: 'CourseDetail',
		path: '/CourseDetail',
		component: CourseDetail,
		visibleTo: [AppRoles.CONTENT_ADMIN, AppRoles.TRAINER],
	},
	{
		exact: true,
		name: 'PublishSettingModal',
		path: '/PublishSettingModal',
		component: PublishSettingModal,
		visibleTo: [AppRoles.CONTENT_ADMIN, AppRoles.TRAINER],
	},

	{
		exact: true,
		name: 'CourseContentPreview',
		path: '/courseContentPreview',
		component: CourseContentPreview,
		visibleTo: [AppRoles.CONTENT_ADMIN, AppRoles.TRAINER],
	},
	{
		exact: true,
		name: 'Users',
		path: '/users',
		component: Users,
		visibleTo: [AppRoles.CONTENT_ADMIN, AppRoles.TRAINER],
	},

	{
		exact: true,
		name: 'UserDetail',
		path: '/userDetail',
		component: UserDetail,
		visibleTo: [AppRoles.CONTENT_ADMIN, AppRoles.TRAINER],
	},
	{
		exact: true,
		name: 'CreateUser',
		path: '/createUser',
		component: CreateUser,
		visibleTo: [AppRoles.CONTENT_ADMIN, AppRoles.TRAINER],
	},

	// student manage UI
	{
		exact: true,
		name: 'MyProfile',
		path: '/myProfile',
		component: MyProfile,
		visibleTo: [AppRoles.CONTENT_ADMIN, AppRoles.TRAINER, AppRoles.PRODUCT_OWNER, AppRoles.STUDENT],
	},

	{
		exact: true,
		name: 'ChangePassword',
		path: '/ChangePassword',
		component: ChangePassword,
		visibleTo: [AppRoles.CONTENT_ADMIN, AppRoles.TRAINER, AppRoles.PRODUCT_OWNER, AppRoles.STUDENT],
	},

	{
		exact: true,
		name: 'CreatGroup',
		path: '/create-group',
		component: CreateGroup,
		visibleTo: [AppRoles.CONTENT_ADMIN],
	},

	{
		exact: true,
		name: 'Groups',
		path: '/groups',
		component: Groups,
		visibleTo: [AppRoles.CONTENT_ADMIN],
	},

	{
		exact: true,
		name: 'CourseScheduleList',
		path: '/cslist',
		component: CourseScheduleList,
		visibleTo: [AppRoles.CONTENT_ADMIN],
	},
	{
		exact: true,
		name: 'CreateCourseSchedule',
		path: '/cscreate',
		component: CreateCourseSchedule,
		visibleTo: [AppRoles.CONTENT_ADMIN],
	},
	{
		exact: true,
		name: 'TrainingInsights',
		path: '/course-training-insights',
		component: TrainingInsights,
		visibleTo: [AppRoles.CONTENT_ADMIN],
	},
	{
		exact: true,
		name: 'TrainingInsightsDetails',
		path: '/training-insight-details',
		component: TrainingInsightsDetails,
		visibleTo: [AppRoles.CONTENT_ADMIN],
	},
	//Course Manager Dashboard
	{
		exact: true,
		name: 'CourseManagerDashboard',
		path: '/course-manager-dashboard',
		component: CourseManagerDashboard,
		visibleTo: [AppRoles.CONTENT_ADMIN],
	},
	//Instructor Dashboard
	{
		exact: true,
		name: 'InstructorDashboard',
		path: '/instructor-dashboard',
		component: InstructorDashboard,
		visibleTo: [AppRoles.TRAINER],
	},
	{
		exact: true,
		name: 'ClientAccountList',
		path: '/clientlist',
		component: ClientAccountList,
		visibleTo: [AppRoles.PRODUCT_OWNER],
	},
	{
		exact: true,
		name: 'CreateClientAccount',
		path: '/createClient',
		component: CreateClientAccount,
		visibleTo: [AppRoles.PRODUCT_OWNER],
	},
	{
		exact: true,
		name: 'ClientDetail',
		path: '/clientDetail',
		component: ClientDetail,
		visibleTo: [AppRoles.PRODUCT_OWNER],
	},
	{
		exact: true,
		name: 'CreateLessons',
		path: '/create-lessons',
		component: CreateLessons,
		visibleTo: [AppRoles.CONTENT_ADMIN, AppRoles.TRAINER],
	},
	{
		exact: true,
		name: 'lessonDetails',
		path: '/lessonDetails',
		component: LessonDetails,
		visibleTo: [AppRoles.CONTENT_ADMIN, AppRoles.TRAINER],
	},
	{
		exact: true,
		name: 'Lessons',
		path: '/create-lesson',
		component: Lessons,
		visibleTo: [AppRoles.CONTENT_ADMIN, AppRoles.TRAINER],
	},
	{
		exact: true,
		name: 'ViewLesson',
		path: '/view-lesson',
		component: ViewLesson,
		visibleTo: [AppRoles.CONTENT_ADMIN, AppRoles.TRAINER, AppRoles.STUDENT],
	},
	{
		exact: true,
		name: 'lessonPreview',
		path: '/lessonPreview',
		component: LessonPreview,
		visibleTo: [AppRoles.CONTENT_ADMIN, AppRoles.TRAINER],
	},
	{
		exact: true,
		name: 'StudentDashboard',
		path: '/student-dashboard',
		component: StudentDashboard,
		visibleTo: [AppRoles.STUDENT],
	},
	{
		exact: true,
		name: 'StudentCourseDetail',
		path: '/student-course-details',
		component: StudentCourseDetail,
		visibleTo: [AppRoles.STUDENT],
	},
	{
		exact: true,
		name: 'create-pa',
		path: '/create-assessment',
		component: PracticalAssessment,
		visibleTo: [AppRoles.CONTENT_ADMIN, AppRoles.TRAINER],
	},
	{
		exact: true,
		name: 'pa-detail',
		path: '/view-assessment',
		component: PracticalAssessmentDetail,
		visibleTo: [AppRoles.CONTENT_ADMIN, AppRoles.TRAINER],
	},
	{
		exact: true,
		name: 'StudentAssessmentDetails',
		path: '/student-assessment-details',
		component: StudentAssessmentDetails,
		visibleTo: [AppRoles.STUDENT],
	},
	{
		exact: true,
		name: 'TopicList',
		path: '/topics',
		component: TopicList,
		visibleTo: [AppRoles.STUDENT],
	},
	{
		exact: true,
		name: 'TopicMasteryQuestionSet',
		path: '/topic-mastery-question-set',
		component: TopicMasteryQuestionSet,
		visibleTo: [AppRoles.STUDENT],
	},
	{
		exact: true,
		name: 'TopicMasteryResult',
		path: '/topic-mastery-result',
		component: MemoryCheckResult,
		visibleTo: [AppRoles.STUDENT],
	},
	{
		exact: true,
		name: 'ResultList',
		path: '/quiz-result',
		component: ResultList,
		visibleTo: [AppRoles.STUDENT],
	},
	{
		exact: true,
		name: 'InstructionDetails',
		path: '/instruction-details',
		component: InstructionDetails,
		visibleTo: [AppRoles.STUDENT],
	},
	{
		exact: true,
		name: 'student-assessment-task-view',
		path: '/student-task-details',
		component: StudentAssessmentTaskView,
		visibleTo: [AppRoles.STUDENT],
	},
	{
		exact: true,
		name: 'RubricAssessmentDetails',
		path: '/scoring-matrix-details',
		component: RubricAssessmentDetails,
		visibleTo: [AppRoles.STUDENT],
	},
	{
		exact: true,
		name: 'SummaryAssessment',
		path: '/assessment-summary',
		component: SummaryAssessment,
		visibleTo: [AppRoles.STUDENT],
	},
	{
		exact: true,
		name: 'ReportsL0',
		path: '/reports',
		component: ReportsL0,
		visibleTo: [AppRoles.CONTENT_ADMIN],
	},
	{
		exact: true,
		name: 'ReportsL1',
		path: '/reports-primary',
		component: ReportsL1,
		visibleTo: [AppRoles.CONTENT_ADMIN],
	},
	{
		exact: true,
		name: 'ReportsL2',
		path: '/reports-secondary',
		component: ReportsL2,
		visibleTo: [AppRoles.CONTENT_ADMIN],
	},
	{
		exact: true,
		name: 'ReportsL3',
		path: '/reports-tertiary',
		component: ReportsL3,
		visibleTo: [AppRoles.CONTENT_ADMIN],
	},
	{
		exact: true,
		name: 'ContentDetails',
		path: '/view-content',
		component: ContentDetails,
		visibleTo: [AppRoles.CONTENT_ADMIN],
	},

	{
		exact: true,
		name: 'ViewFAQ',
		path: '/ViewFAQ',
		component: ViewFAQ,
		visibleTo: [AppRoles.PRODUCT_OWNER],
	},
	{
		exact: true,
		name: 'ListFAQ',
		path: '/manageFAQ',
		component: ListFAQ,
		visibleTo: [AppRoles.PRODUCT_OWNER],
	},
	{
		exact: true,
		name: 'CreateFAQ',
		path: '/createFAQ',
		component: CreateFAQ,
		visibleTo: [AppRoles.PRODUCT_OWNER],
	},

	{
		name: 'BillingInfo',
		path: '/billingInfo',
		component: BillingInfo,
		visibleTo: [AppRoles.CONTENT_ADMIN],
	},
	{
		name: 'NoAccessPage',
		path: '/pageError',
		component: NoAccessPage,
		visibleTo: [AppRoles.CONTENT_ADMIN, AppRoles.TRAINER, AppRoles.STUDENT, AppRoles.PRODUCT_OWNER],
	},
];

export default routes;
