import { lazy } from 'react';

const Home = lazy(() => import('../pages/HomePage/HomePage'));
const Login = lazy(() => import('../pages/LoginPage/LoginPage'));
const Signup = lazy(() => import('../pages/SignupPage/SignupPage'));
const verifyEmail = lazy(() => import('../pages/LoginPage/EmailVerification'));
const ForgotPassword = lazy(() => import('../pages/PasswordChange/ForgotPasswordHander'));
const ResetPassword = lazy(() => import('../pages/PasswordChange/PasswordReset'));

// Terms and Conditions
const TermsAndConditions = lazy(() => import('../pages/Terms&Conditions/TermsandConditions'));
const PrivacyPolicy = lazy(() => import('../pages/Terms&Conditions/PrivacyPolicies'));
const Disclaimer = lazy(() => import('../pages/Terms&Conditions/DisclaimerPage'));

//SelfServeSignup

const AdminDetails = lazy(() => import('../pages/SelfServeSignUp/AdminDetails'));
const BusinessTypeSelector = lazy(() => import('../pages/SelfServeSignUp/BusinessTypeSelector'));
const PricingDisplay = lazy(() => import('../pages/SelfServeSignUp/PricingDisplay'));
const BrandingPage = lazy(() => import('../pages/SelfServeSignUp/BrandingPage'));
const PaymentConfirmation = lazy(() => import('../pages/SelfServeSignUp/PaymentConfirmation'));

//FAQ Homepage
const FAQHomePage = lazy(() => import('../pages/FAQ/FAQHomePage'));

export default [
	{
		exact: true,
		name: 'Home',
		path: '/',
		component: Home,
	},
	{
		exact: true,
		name: 'Login',
		path: '/login',
		component: Login,
	},
	{
		exact: true,
		name: 'Signup',
		path: '/signup',
		component: Signup,
	},
	{
		exact: true,
		name: 'AdminDetails',
		path: '/admin-details',
		component: AdminDetails,
	},
	{
		exact: true,
		name: 'BusinessTypeSelector',
		path: '/organization-type',
		component: BusinessTypeSelector,
	},
	{
		exact: true,
		name: 'BrandingPage',
		path: '/BrandingPage',
		component: BrandingPage,
	},
	{
		exact: true,
		name: 'PricingDisplay',
		path: '/pricing',
		component: PricingDisplay,
	},
	{
		exact: true,
		name: 'PaymentConfirmation',
		path: '/confirm',
		component: PaymentConfirmation,
	},
	{
		exact: true,
		name: 'verifyEmail',
		path: '/verifyEmail',
		component: verifyEmail,
	},
	{
		exact: true,
		name: 'ForgotPassword',
		path: '/ForgotPassword',
		component: ForgotPassword,
	},
	{
		exact: true,
		name: 'ResetPassword',
		path: '/ResetPassword',
		component: ResetPassword,
	},
	{
		exact: true,
		name: 'termsAndConditions',
		path: '/termsAndConditions',
		component: TermsAndConditions,
	},
	{
		exact: true,
		name: 'privacyPolicy',
		path: '/privacyPolicy',
		component: PrivacyPolicy,
	},
	{
		exact: true,
		name: 'disclaimer',
		path: '/disclaimer',
		component: Disclaimer,
	},
	{
		exact: true,
		name: 'FAQHomePage',
		path: '/FAQ',
		component: FAQHomePage,
	},
];
