import { DELETE, GET, PUT } from '../../services/http';
import { dateFormatterApi } from '../../helpers/utilities';
import { StorageKey } from '../../helpers/constants';
import { setCache } from '../../services/storage';

const FILE = 'FILE://CURRICULUM';

const generateActionType = type => `${FILE}/${type}`;

const GET_CURRICULUM_DATA = generateActionType('GET_CURRICULUM_DATA');
const GET_RECENT_DATA = generateActionType('GET_RECENT_DATA');
const GET_STATISTICS = generateActionType('GET_STATISTICS');
const GET_TAGS_LIST = generateActionType('GET_TAGS_LIST');
const GET_ROLE_LIST = generateActionType('GET_ROLE_LIST');
const GET_DEPT_LIST = generateActionType('GET_DEPT_LIST');
const GET_COUNTRY_CODE = generateActionType('GET_COUNTRY_CODE');

export const getCurriculumData = data => ({
	type: GET_CURRICULUM_DATA,
	data,
});
export const getRecentData = data => ({
	type: GET_RECENT_DATA,
	data,
});
export const getStatistics = data => ({
	type: GET_STATISTICS,
	data,
});

export const getTagsList = data => ({
	type: GET_TAGS_LIST,
	data,
});

export const tagsList = async dispatch => {
	const req = {
		url: `/tags`,
	};
	const response = await GET(req);
	if (response.status === 200) {
		dispatch(getTagsList(response));
		return response;
	}
};
export const editTag = async (oldName, newName) => {
	const req = {
		url: `/tags`,
		data: { oldName: oldName, newName: newName },
	};
	const response = await PUT(req);
	return response;
};

export const deleteRecentList = async contentId => {
	const req = {
		url: `/curriculums/${contentId}`,
	};
	const response = await PUT(req);
	return response;
};

export const deleteTag = async tagName => {
	const req = {
		url: `/tags`,
		data: { name: tagName },
	};
	const response = await DELETE(req);
	return response;
};
export const listRecent = async () => {
	const req = {
		url: `/curriculums/recent?limit=6`,
	};
	const response = await GET(req);
	if (response.status === 200) {
		return response;
	}
};
export const statistics = async type => {
	let req = {};
	let url = '/curriculums/statistics';
	if (type) {
		url += `?type=${type}`;
	}
	req = {
		url,
	};
	const response = await GET(req);
	if (response.status === 200) {
		return response;
	}
};

// search all and course
export const searchCuriculumData = async (sortKey, sortOrderAsc, key) => {
	const sortOrder = sortOrderAsc ? 'asc' : 'desc';
	let req = {};
	let url = `curriculums?limit=${20}&page=${1}&sortKey=${sortKey}&sortOrder=${sortOrder}`;
	if (key.trim().length) {
		let keyword;
		if (key.includes('#') || key.includes('&')) {
			keyword = encodeURIComponent(key);
		} else {
			keyword = key
				.trim()
				.toLowerCase()
				.replace(/[^a-z\d#&]/g, '\\$&');
		}
		// let keyword = key
		// 	.toLowerCase()
		// 	.trim()
		// 	.replace(/[^a-z\d#&]/g, '\\$&');

		url += `&keyword=${keyword.trim()}`;
	}
	req = {
		url,
	};

	const response = await GET(req);
	if (response.status === 200) {
		return response;
	}
};
// all
export const listCurriculumData = async (
	page,
	pageSize,
	sortKey,
	sortOrderAsc,
	keyword,
	globalSearchAll,
	searchKey,
	startDate1,
	endDate1,
	startDate2,
	endDate2,
	selected,
	selectedType,
	allFlag
) => {
	const sortOrder = sortOrderAsc ? 'asc' : 'desc';
	let req = {};
	let url = `curriculums?limit=${pageSize}&page=${page}&sortKey=${sortKey}&sortOrder=${sortOrder}`;
	if (searchKey && keyword?.trim().length) {
		url += `&searchKey=${searchKey}`;
	}
	if (keyword?.trim().length) {
		let key;
		if (keyword.includes('#') || keyword.includes('&')) {
			key = encodeURIComponent(keyword);
		} else {
			key = keyword
				.trim()
				.toLowerCase()
				.replace(/[^a-z\d#&]/g, '\\$&');
		}
		// let key = keyword
		// 	.toLowerCase()
		// 	.trim()
		// 	.replace(/[^a-z\d#&]/g, '\\$&');
		url += `&keyword=${key?.trim()}`;
	}
	if (globalSearchAll?.trim().length) {
		let key;
		if (globalSearchAll.includes('#') || globalSearchAll.includes('&')) {
			key = encodeURIComponent(globalSearchAll);
		} else {
			key = globalSearchAll
				.trim()
				.toLowerCase()
				.replace(/[^a-z\d#&]/g, '\\$&');
		}
		// let key = globalSearchAll
		// 	.toLowerCase()
		// 	.trim()
		// 	.replace(/[^a-z\d#&]/g, '\\$&');
		url += `&keyword=${key?.trim()}`;
	}
	if (selected.length > 0 && allFlag === null) {
		url += `&status=${selected.map(x => x.value).join(',')}`;
	}
	if (selectedType.length > 0) {
		url += `&type=${selectedType.map(x => x.value).join(',')}`;
	}
	// if (selectTags.length > 0) {
	// 	url += `&tags=${selectTags.map(x => x.value).join(',')}`;
	// }
	if (startDate1 && endDate1) {
		url += `&updatedDateFrom=${dateFormatterApi(startDate1)}&updatedDateTo=${dateFormatterApi(
			endDate1
		)}`;
	}
	if (startDate2 && endDate2) {
		url += `&reviewDateFrom=${dateFormatterApi(startDate2)}&reviewDateTo=${dateFormatterApi(
			endDate2
		)}`;
	}
	if (allFlag === 1) {
		url += `&status=published`;
	}
	if (allFlag === 2) {
		url += `&status=draft`;
	}
	if (allFlag === 3) {
		url += `&draftLimit=${true}`;
	}
	if (allFlag === 4) {
		url += `&reviewList=${true}`;
	}
	req = {
		url,
	};

	const response = await GET(req);
	if (response.status === 200) {
		return response;
	}
};

// course
export const listCourseData = async (
	page,
	pageSize,
	globalSearchC,
	filterStatus,
	filterTags,
	filterFromDate,
	filterToDate,
	filterLanguage,
	cFlag
) => {
	// const sortOrder = sortOrderAsc ? 'asc' : 'desc';
	let req = {};
	let url = `courses?limit=${pageSize}&page=${page}`;

	if (globalSearchC?.trim().length) {
		let key;
		if (globalSearchC.includes('#') || globalSearchC.includes('&')) {
			key = encodeURIComponent(globalSearchC);
		} else {
			key = globalSearchC
				.trim()
				.toLowerCase()
				.replace(/[^a-z\d#&]/g, '\\$&');
		}
		// let key = globalSearchC
		// 	.toLowerCase()
		// 	.trim()
		// 	.replace(/[^a-z\d#&]/g, '\\$&');
		url += `&keyword=${key?.trim()}`;
	}
	if (filterStatus.length > 0 && cFlag === null) {
		url += `&status=${filterStatus.map(x => x.value).join(',')}`;
	}
	if (filterTags.length > 0) {
		url += `&tags=${filterTags.map(x => x.value).join(',')}`;
	}
	if (filterToDate && filterFromDate) {
		url += `&dateFrom=${dateFormatterApi(filterFromDate)}&dateTo=${dateFormatterApi(filterToDate)}`;
	}
	if (filterLanguage.length > 0) {
		url += `&language=${filterLanguage.map(x => x.value).join(',')}`;
	}
	if (cFlag === 1) {
		url += `&status=published`;
	}
	if (cFlag === 2) {
		url += `&status=draft`;
	}
	if (cFlag === 3) {
		url += `&draftLimit=${true}`;
	}
	if (cFlag === 4) {
		url += `&reviewList=${true}`;
	}

	req = {
		url,
	};

	const response = await GET(req);
	if (response.status === 200) {
		return response;
	}
};

export const getRoleList = data => ({
	type: GET_ROLE_LIST,
	data,
});
export const getDeptList = data => ({
	type: GET_DEPT_LIST,
	data,
});
export const getCountryCode = data => ({
	type: GET_COUNTRY_CODE,
	data,
});

export const roleList = async dispatch => {
	const req = {
		url: `/roles`,
	};

	const response = await GET(req);
	if (response?.status === 200) {
		dispatch(getRoleList(response));
		return response;
	}
};
export const countryCode = async dispatch => {
	const req = {
		url: `/country-codes`,
	};

	const response = await GET(req);
	if (response?.status === 200) {
		dispatch(getCountryCode(response));
		return response;
	}
};

export const deptList = async dispatch => {
	const req = {
		url: `/departments`,
	};

	const response = await GET(req);
	if (response?.status === 200) {
		dispatch(getDeptList(response));
		return response;
	}
};

function reducer(state, action) {
	switch (action.type) {
		case GET_CURRICULUM_DATA:
			return {
				...state,
				...action.data.data,
			};
		case GET_TAGS_LIST:
			return {
				...state,
				tags: action.data.data.data,
				// ...action.data,
			};
		case GET_RECENT_DATA:
			return {
				...state,
				...action.data,
			};
		case GET_STATISTICS:
			return {
				...state,
				...action.data,
			};
		case GET_ROLE_LIST:
			setCache(StorageKey.ROLES, action.data.data.data);

			return {
				...state,
				roles: action.data.data.data,
			};
		case GET_DEPT_LIST:
			setCache(StorageKey.DEPTS, action.data.data.data);

			return {
				...state,
				dept: action.data.data.data,
			};

		case GET_COUNTRY_CODE:
			setCache(StorageKey.COUNTRY_CODES, action.data.data.data);

			return {
				...state,
				countryCode: action.data.data.data,
			};
		default:
			return null;
	}
}
export default reducer;
