export const Pattern = {
	EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

export const StorageKey = {
	TMS_USER: 'tms_user',
	LESSON_DATA: 'lesson_data',
	RUBRICS_DATA: 'rubrics_data',
	ASSESSMENT_DATA: 'assessment_data',
	COURSE_DATA: 'course_data',
	USERS_DATA: 'users_data',
	TMS_EMAIL: 'tms - email',
	TMS_USER_EMAIL: 'tms - user_email',
	TMS_PWD: 'tms - pwd',
	QTMS_PWD: 'qtms - pwd',
	TMS_UID: 'tms - id',
	TMS_IS_REMEMBER_ME: 'tms- isRem',
	ROLES: 'roles',
	COUNTRY_CODES: 'countryCodes',
	DEPTS: 'departments',
	TAB: 'first',
	GROUP_DATA: 'group_data',
	COURSE_SCHEDULE: 'course_schedule',
	PRACTICAL_ASSESSMENT: 'practical_assessment',
	STUDENT: 'student',
	TENANT: 'tenant',
	POC: 'poc',
	CLIENT_DATA: 'client_data',
	CREATED_BY_ADMIN: 'created_by_admin',
	USERID: 'userId',
};

export const ContentTypeList = {
	QUESTION: 'question',
	TEXT: 'text',
	IMAGE: 'image',
	VIDEO: 'video',
	AUDIO: 'audio',
	DOCUMENT: 'document',
	PDF: 'pdf',
	ONLINE_VIDEO: 'online-video',
	MEDIA: 'media',
	INFO_BOX: 'infoBox',
	ONLINE_CONTENT: 'onlineContent',
	LIST: 'list',
};

export const ScreenIdentifier = {
	RUBRIC: 'Rubric',
	PRACTICAL_ASSESSMENT: 'Practical assessment',
	EVALUATION: 'Evaluation',
	LESSON: 'Lesson',
	CURRICULUM: 'Curriculum',
	COURSE: 'Course',
	QUIZ: 'Quiz',
	Assignment: 'Assignment',
	USERS: 'User',
	CONTENT: 'Content',
	GROUPS: 'Groups',
	COURSE_SCHEDULE: 'Session',
	REPORTS: 'Reports',
	STUDENTS: 'students',
	CLIENT_ACCOUNTS: 'Add New Client',
	MY_PROFILE: 'My Profile',
	CLIENT: 'Client Account',
	GROUP: 'Group',
	ASSESSMENT: 'Assessment',
	TASKS: 'Tasks',
	TOPIC_MASTERY: 'Topic Mastery',
	PRETEST: 'Pretest',
	FAQ: 'FAQ',
	MANAGE_FAQ: 'Manage FAQ',
	SYSTEM_SETTINGS: 'System Settings',
	REPORTSL3: 'ReportsL3',
};

export const ReportType = {
	SESSIONS: 'Sessions',
	GROUPS: 'Groups',
	FEEDBACK: 'Feedback',
	PROGRESS: 'Progress',
	RESULTS: 'Results',
	IMPACT: 'Impact',
	DEPARTMENTS: 'Departments',
	BETWEEN_DATES: 'Between Dates',
	REPORT_TYPE: 'reportType',
	REPORT_DURATION: 'reportDuration',
	TRAINING_IMPACT: 'Training Impact',
	REPORTSL2: 'reportsL2',
	FINAL: 'final',
};

export const ReportStatus = {
	SCHEDULED: 'scheduled',
	COMPLETED: 'completed',
	OVERDUE: 'overdue',
	INPROGRESS: 'inProgress',
	ALL: 'All',
	STATUS: 'status',
	NOT_STARTED: 'notStarted',
};

export const TrainingInsightsStatus = {
	IN_PROGRESS: 'In progress',
	COMPLETED: 'Completed',
	SCHEDULED: 'Scheduled',
	OVERDUE: 'Overdue',
	ACTIVE: 'Active',
	CLOSED: 'Closed',
};

export const AutoSaveState = {
	NOT_SAVED: 'Not saved',
	SAVING: 'Saving...',
	LAST_SAVED_ON: 'Last saved',
};

export const AppRoles = {
	TRAINER: 'instructor',
	CONTENT_ADMIN: 'courseManager',
	PRODUCT_OWNER: 'superAdmin',
	STUDENT: 'student',
};

export const RoleLabels = {
	TRAINER: 'Trainer',
	CONTENT_ADMIN: 'Training Admin',
	PRODUCT_OWNER: 'Product Owner',
	LEARNER: 'Learner',
};
export const csvData = [
	['Instructions:'],
	['1. Columns marked * are mandatory'],
	['2. Country code should contain numbers only'],
	[
		'3. Allocate specific users to the training admin role by placing a ""Y"" in the ""Training Admin?"" column',
	],
	[
		'-----------------------------------------------------------------------------------------------------------',
	],
	[
		'Given Name *',
		'Family Name *',
		'Email *',
		'Country Code',
		'Phone Number',
		'Work Department *',
		'Training Admin?',
	],
	['Jacob', 'John', 'example@gmail.com', '+91', '9712345678', 'Finance Department', 'Y'],
	['Joseph', 'Antony', 'test@gmail.com', '+91', '9789654321', 'IT Department', 'N'],
];

export const ACTION = {
	NEW: 'new',
	MODIFIED: 'modified',
	DELETED: 'deleted',
	NOCHANGE: 'no-change',
};

export const ContentTypes = {
	PRACTICAL_ASSESSMENT: 'Practical Assessment',
	RUBRIC: 'Rubric',
};
