import { POST, PATCH } from '../../services/http';
import { getCache, setCache } from '../../services/storage';
import { StorageKey } from '../../helpers/constants';

const FILE = 'FILE://LOGIN';

const generateActionType = type => `${FILE}/${type}`;

const LOGIN_USER = generateActionType('LOGIN_USER');

export const saveUser = data => ({
	type: LOGIN_USER,
	data,
});

export const authenticateUser = async credential => {
	const req = {
		url: 'accounts/signin',
		data: {
			email: credential.email,
			password: credential.password,
		},
	};

	const response = await POST(req);
	return response;
};
export const logoutUser = async () => {
	const req = {
		url: 'accounts/logout',
	};

	const response = await POST(req);

	return response;
};

export const handleReSubscription = async clientId => {
	const req = {
		url: `payment/create-checkout-session?page=signUp&clientId=${clientId}&createNew=${true}`,
	};

	const response = await POST(req);

	return response;
};

export const handleStripeRedirection = async clientId => {
	const req = {
		url: `payment/create-checkout-session?page=signUp&update=${false}&clientId=${clientId}`,
	};

	const response = await POST(req);

	return response;
};

export const forgotPassword = async email => {
	const req = {
		url: `/accounts/forgotPassword`,
		data: { email: email },
	};

	const response = await PATCH(req);
	return response;
};
export const verifyMail = async (id, resetCode) => {
	const req = {
		url: `/users/${id}/verify/email`,
		data: { resetCode: resetCode },
	};

	const response = await PATCH(req);
	return response;
};

function reducer(state, action) {
	const initialData = getCache(StorageKey.TMS_USER);
	switch (action.type) {
		case LOGIN_USER:
			setCache(StorageKey.TMS_USER, action.data);
			return {
				...state,
				...action.data,
			};
		default:
			return initialData;
	}
}
export default reducer;
