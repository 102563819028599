import { StorageKey } from '../../helpers/constants';
import { GET, POST, PATCH, PUT } from '../../services/http';
import { setCache, getCache } from '../../services/storage';
const FILE = 'FILE://STUDENT';
const generateActionType = type => `${FILE}/${type}`;

const GET_ASSESSMENT_DETAILS = generateActionType('GET_ASSESSMENT_DETAILS');
const GET_SKILL_PROFILE = generateActionType('GET_SKILL_PROFILE');
export const skillProfile = data => ({
	type: GET_SKILL_PROFILE,
	data,
});
export const assessmentDetails = data => ({
	type: GET_ASSESSMENT_DETAILS,
	data,
});
export const courseStatistics = async () => {
	const req = {
		url: `/students/courses/statistics`,
	};

	const response = await GET(req);
	if (response.status === 200) {
		return response;
	}
};

export const getSkillProfile = async dispatch => {
	const req = {
		url: `/topicMastery/topic-mastery-score`,
	};

	const response = await GET(req);
	if (response.status === 200) {
		dispatch(skillProfile(response?.data?.data));
		return response;
	}
};

export const getCoursesInprogress = async () => {
	const req = {
		url: `/students/courses-progress`,
	};

	const response = await GET(req);
	if (response.status === 200) {
		return response;
	}
};

export const getCourseDetails = async courseId => {
	const req = {
		url: `/students/courses-progress/${courseId}`,
	};

	const response = await GET(req);
	if (response.status === 200) {
		return response;
	}
};

export const getLessonDetails = async (progressId, lessonId, moduleId) => {
	const req = {
		url: `students/courses-progress/${progressId}/lessons/${lessonId}?sectionId=${moduleId}`,
	};

	const response = await GET(req);
	if (response.status === 200) {
		return response;
	}
};

export const getAssessmentDetails = async (assessmentId, progressId, moduleId) => {
	const req = {
		url: `/students/assessment/${assessmentId}?progressId=${progressId}&sectionId=${moduleId}`,
	};

	const response = await GET(req);
	if (response.status === 200) {
		return response;
	}
};

export const getCourseFeedbackQuestions = async () => {
	const req = {
		url: `/survey/feedbackQuestions`,
	};

	const response = await GET(req);
	if (response.status === 200) {
		return response;
	}
};

export const getStudentRubricDetails = async (assessmentId, sessionId, studentId, moduleId) => {
	const req = {
		url: `/courseAssessments/rubrics/${assessmentId}?sessionId=${sessionId}&studentId=${studentId}&sectionId=${moduleId}`,
	};

	const response = await GET(req);
	if (response.status === 200) {
		return response;
	}
};

export const getStudentTaskDetails = async (assessmentId, sessionId, studentId, moduleId) => {
	const req = {
		url: `/courseAssessments/tasks/${assessmentId}?sessionId=${sessionId}&studentId=${studentId}&sectionId=${moduleId}`,
	};

	const response = await GET(req);
	if (response.status === 200) {
		return response;
	}
};

export const getAssessmentResult = async (assessmentId, sessionId, studentId, moduleId) => {
	const req = {
		url: `/courseAssessments/grade/${sessionId}/${assessmentId}/${studentId}?sectionId=${moduleId}`,
	};

	const response = await GET(req);
	if (response.status === 200) {
		return response;
	}
};

export const saveAnswer = async (progressId, lessonId, answerData) => {
	const req = {
		url: `students/courses-progress/${progressId}/lessons/${lessonId}/answer`,
		data: answerData,
	};

	const response = await POST(req);
	if (response.status === 200) {
		return response;
	}
};

export const saveStudentStatus = async (progressId, contentData) => {
	const req = {
		url: `/students/startDate/${progressId}`,
		data: contentData,
	};

	const response = await POST(req);
	if (response.status === 200) {
		return response;
	}
};

export const savePretestStatus = async (topicId, contentData) => {
	const req = {
		url: `/topicMastery/attendedDate/${topicId}`,
		data: contentData,
	};

	const response = await POST(req);
	if (response.status === 200) {
		return response;
	}
};

export const completeLesson = async (progressId, lessonId, data) => {
	const req = {
		url: `students/courses-progress/${progressId}/lessons/${lessonId}/status`,
		data,
	};

	const response = await POST(req);
	if (response.status === 200) {
		return response;
	}
};

export const topicMasteryList = async sortOrderAsc => {
	const sortType = sortOrderAsc ? 'asc' : 'desc';
	const req = {
		url: `topicMastery?sort=${sortType}`,
	};

	const response = await GET(req);
	if (response.status === 200) {
		return response;
	}
};

export const pretestResult = async (topicId, courseScheduleId) => {
	const req = {
		url: `/topicMastery/preTest/result/${topicId}/${courseScheduleId}`,
	};

	const response = await GET(req);
	if (response.status === 200) {
		return response;
	}
};
export const pretestQuestionsList = async (topicId, courseScheduleId) => {
	const req = {
		url: `topicMastery/preTest/questions/${topicId}/${courseScheduleId}`,
	};

	const response = await GET(req);
	if (response.status === 200) {
		return response;
	}
};

export const submitPretestAnswer = async (answer, topicId, courseScheduleId) => {
	const req = {
		url: `topicMastery/preTest/answer/${topicId}/${courseScheduleId}`,
		data: answer,
	};

	const response = await PATCH(req);
	if (response.status === 200) {
		return response;
	}
};

export const memoryCheckResult = async topicId => {
	const req = {
		url: `/topicMastery/postTest/result/${topicId}`,
	};

	const response = await GET(req);
	if (response.status === 200) {
		return response;
	}
};
export const memoryCheckQuestionsList = async topicId => {
	const req = {
		url: `topicMastery/postTest/questions/${topicId}`,
	};

	const response = await GET(req);
	if (response.status === 200) {
		return response;
	}
};

export const submitmemoryCheckAnswer = async (answer, topicId, courseScheduleId) => {
	const req = {
		url: `topicMastery/postTest/answer/${topicId}/${courseScheduleId}`,
		data: answer,
	};

	const response = await PATCH(req);
	if (response.status === 200) {
		return response;
	}
};

export const submitmemoryCheckScore = async topicId => {
	const req = {
		url: `/topicMastery/prevScore/${topicId}`,
	};

	const response = await PATCH(req);
	if (response.status === 200) {
		return response;
	}
};

export const submitFeedback = async (progressId, lessonData) => {
	let response;
	const req = {
		url: `/survey/contentFeedback/${progressId}`,
		data: lessonData,
	};
	response = await PUT(req);
	return response;
};

export const submitCourseFeedback = async (progressId, courseData) => {
	let response;
	const req = {
		url: `/survey/courseFeedback/${progressId}`,
		data: courseData,
	};
	response = await PUT(req);
	return response;
};

function reducer(state, action) {
	const initialData = getCache(StorageKey.STUDENT);

	switch (action.type) {
		case GET_SKILL_PROFILE:
			setCache(StorageKey.STUDENT, {
				student: { ...state?.student, skillProfile: { ...action.data } },
			});
			return {
				...state,
				student: { ...state?.student, skillProfile: action.data },
			};
		case GET_ASSESSMENT_DETAILS:
			return {
				...state,
				assessment: action.data,
			};
		default:
			return initialData;
	}
}
export default reducer;
